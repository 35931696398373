<template>
  <div>
    <div class="py-5">
      <div class="bg-white p-4 mycard mycontainer">
        <div v-show="step === 1">
          <h3 class="my-4 text-dark-blue text-center">Add an Account</h3>
          <div class="m-4 text-center">
            <p>Please enter a 10 digits phone number you wish to add:</p>
            <p>(eg: 6048886666)</p>
            <div class="inputWrapper mt-4">
              <b-form-input
                v-model="number"
                type="tel"
                class="mt-2 p-4 pl-5"
                placeholder="Phone Number"
                required
              ></b-form-input>
              <i class="fas fa-phone"></i>
            </div>
          </div>
          <div class="text-center" style="margin-top:30px;">
            <button type="submit" class="mybtn" @click="handleStep1()">
              next
            </button>
            <p class="text-dark-blue m-3">
              <span @click="goBack()">Cancel</span>
            </p>
          </div>
        </div>

        <div v-show="step === 2">
          <h3 class="my-4 text-dark-blue text-center">
            Review & Confirmation
          </h3>
          <div class="m-4">
            <p>
              Please review the information you have provided and confirm it is
              correct before your application PhoneBox. If you choose to edit
              any information, you will need to follow the steps back to this
              review page.
            </p>
            <br />
            <p>You will not re-enter any information.</p>
            <ul>
              <!-- <li>
                <span class="w-500">Main Phone Number:</span>
                {{ mainAccountNumber }}
              </li> -->
              <li>
                <span class="w-500">Request:</span> Add a phone number to the
                main account
              </li>
              <li>
                <span class="w-500">Subject Phone Number:</span>
                {{ processNumberFormat(number) }}
              </li>
              <li><span class="w-500">Effective Date:</span> Today</li>
            </ul>
            <p>
              Please review the terms and conditions on making a change to your
              account. You are requesting a transfer of responsibility for the
              phone number you entered.
            </p>
            <br />
            <el-checkbox v-model="hasReview">
              <p style="font-size: 16px;">I accept the Terms & Conditions</p>
            </el-checkbox>
            <div class="text-center" style="margin-top:30px;">
              <button class="mybtn" @click="handleStep2()">
                next
              </button>
              <p class="text-dark-blue m-3">
                <span @click="step--" class="mr-4"> Back</span>
                <span @click="goBack()">Cancel</span>
              </p>
            </div>
          </div>
        </div>

        <div v-show="step === 3">
          <h3 class="my-4 text-dark-blue  text-center">Verification</h3>
          <div class="m-4 text-center">
            <p>
              Please enter the 6 digits code in the SMS from this number: <br />
              {{ processNumberFormat(number) }}
            </p>
            <div class="inputWrapper mt-4">
              <b-form-input
                v-model="code"
                type="tel"
                class="mt-2 p-4 pl-5"
                placeholder="6 digits code"
                required
              ></b-form-input>
              <i class="fas fa-lock"></i>
            </div>
          </div>

          <div class="text-center">
            <p style="font-size: 16px;">
              <span
                style="font-weight:300"
                :style="resendBtnStyle"
                @click="
                  handleStep2();
                  setTheTimer();
                "
                >Resend Code</span
              >
              <span v-show="timer !== 0" class="timerS">{{ timer }} s</span>
            </p>
          </div>

          <div class="text-center" style="margin-top:30px;">
            <button class="mybtn" @click="handleStep3()">
              next
            </button>
            <p class="text-dark-blue m-3">
              <span @click="step--" class="mr-4">Back</span>
              <span @click="goBack()">Cancel</span>
            </p>
          </div>
        </div>

        <!-- <div v-show="step === 4">
          <h3 class="my-4 text-dark-blue text-center">Account Added ✓</h3>
          <div class="m-4 text-center">
            <p>We have added the account to your PhoneBox main account.</p>
            <button class="mybtn mt-4" @click="goBack()">
              back to my account
            </button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
      hasReview: false,
      number: "",
      code: "",
      setTimer: null,
      timer: 60,
    };
  },
  computed: {
    mainAccountNumber() {
      return this.processNumberFormat(
        this.$store.state.mutilLinesAccounts[0].Pnum
      );
    },
    resendBtnStyle() {
      if (this.timer === 0) {
        return {
          opacity: "1",
        };
      } else {
        return {
          "pointer-events": "none",
          opacity: "0.5",
        };
      }
    },
    clientType() {
      return this.$store.state.login.clientType;
    },
  },
  methods: {
    processNumberFormat(num) {
      return (
        "+1 (" + num.slice(0, 3) + ") " + num.slice(3, 6) + " - " + num.slice(6)
      );
    },
    confirmationMessage() {
      this.$notify({
        title: "Warning",
        message: "Please accept the Terms & Conditions.",
        type: "warning",
      });
    },
    goBack() {
      this.$router.push(this.$store.state.login.preRoute);
    },
    handleStep1() {
      if (this.number === "") {
        this.$notify.error({
          title: "Error !",
          message: "Phone number can not be empty!",
        });
      } else {
        this.$axios
          .get("Account/CheckAcctAvailability", {
            params: {
              acctMemberPnum: this.number,
              usageType: "AddLine",
            },
          })
          .then((response) => {
            let data = JSON.parse(response.data);
            if (data === "success") {
              this.step++;
            } else {
              this.$notify.error({
                title: "Error !",
                message: data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleStep2() {
      if (this.hasReview) {
        this.$axios
          .get("LoginRegistration/GetSMSCode", {
            params: {
              pnum: this.number,
              usageType: "AddLine",
            },
          })
          .then(() => {
            if (this.step === 2) this.step++;
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error !",
              message: "Sending SMS code error!",
            });
          });
      } else {
        this.confirmationMessage();
      }
    },
    handleStep3() {
      if (this.code === "") {
        this.$notify.error({
          title: "Error !",
          message: "SMS code can not be empty!",
        });
      } else {
        let url =
          "Account/AddLine?" +
          `acctMemberPnum=${this.number}&verificationCode=${this.code}
        `;
        this.$axios
          .post(url)
          .then((response) => {
            let data = JSON.parse(response.data);
            if (data === "Wrong Code!") {
              this.$notify.error({
                title: "Error !",
                message: data,
              });
            } else {
              this.$router.push(
                this.clientType === "postpaid" ? "home" : "prepaid-home"
              );
              this.$notify({
                title: "Success",
                message: "You have successfully added an account!",
                type: "success",
              });
            }
          })
          .catch((error) => {
            this.$notify.error({
              title: "Error !",
              message: error,
            });
          });
      }
    },
    setTheTimer() {
      this.timer = 60;
      this.setTimer = setInterval(() => {
        this.timer--;
      }, 1000);
    },
  },
  watch: {
    step(value) {
      if (value === 3) {
        this.setTheTimer();
      } else {
        clearInterval(this.setTimer);
      }
    },
    timer(value) {
      if (value === 0) {
        clearInterval(this.setTimer);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 18px;
  margin: 0;
}

.inputWrapper {
  width: 70%;
  margin: auto;
  position: relative;
  font-size: 18px !important;
  i {
    color: #d2d2d2;
    position: absolute;
    font-size: 18px;
    top: 16px;
    left: 16px;
  }
}

.mycontainer {
  width: 40%;
  margin: auto;
}

span {
  font-weight: 500;
}
span:hover {
  cursor: pointer;
}

.timerS {
  font-weight: 300;
  opacity: 0.5;
  margin-left: 5px;
  pointer-events: none;
}

@media (max-width: 1360px) {
  .mycontainer {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .mybtn-blue {
    margin: 20px 0px;
  }

  .mycontainer {
    width: 90%;
  }

  .inputWrapper {
    width: 100%;
  }
}
</style>
